












































































































































import { Component, Vue } from "vue-property-decorator";
import {
  TitleTemplatePublic,
  SelectedCountry,
  RedCapital,
  RedCapitalConfig
} from "@/configuration";
import { mapState } from "vuex";
import API from "@/api";
import Axios from "axios";

@Component({
  computed: {
    ...mapState([
      "user",
      "gatewayTransaction",
      "transactionIsPendingPayment",
      "lastCod"
    ])
  },
  metaInfo: {
    title: "Pago exitoso",
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  data() {
    return {
      RedCapital,
      SelectedCountry,
      transaction: null,
      codigo_inversion: null
    };
  },
  beforeMount() {
    this.$store.commit("resetGatewayTransactionConfirmation");
    this.$data.transaction = this.$store.state.gatewayTransaction;
    if (this.$store.state.gatewayTransaction != null) {
      if (!this.$store.state.gatewayTransactionConfirmed) {
        if (this.$store.state.transactionIsPendingPayment) {
          API.khipu.confirm
            .pending(this.$data.transaction)
            .then((res: any) => {
              console.log(res);
              this.$store.commit("confirmGatewayTransaction");
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          API.khipu.confirm
            .normal(this.$data.transaction)
            .then(res => {
              console.log(res);
              this.$store.commit("confirmGatewayTransaction");
            })
            .catch(error => {
              console.log(error);
            });
        }
      }
    }
  }
})
export default class SuccessfulPayment extends Vue {}
